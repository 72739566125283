.auth-app-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: linear-gradient(to right, rgb(23, 36, 62), #32415C, #2a4475);
    color: white;
    padding: 10px 20px; /* Adjust the padding to reduce the header's height */
}

.header-container {
    background: linear-gradient(to right, rgb(23, 36, 62), #32415C, #2a4475);
    text-align: left;
    padding: 10px 0; /* Adjust the padding to match the header's padding */
}

.auth-logo {
    display: flex;
    align-items: center;
    gap: 10px;
}

.auth-logo img {
    width: 45px; /* Adjust the logo width */
    height: auto; /* Let the height adjust automatically to maintain aspect ratio */
}
.teva {
    margin: 0;
}

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start; /* Align content to the top of the container */
    height: 100vh; /* Adjust this value if needed */
    margin-top: 20px; /* Add separation between header and content */
  }
  .centered-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto; /* Horizontally center the content */
  }
  .date-inputs {
    margin-top: 0px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-bottom: 5px;
    align-items: flex-start;
  }
  
  .filter-header {
    color: black;
    padding-left: 40px;
  }
  
  .date-input {
    display: flex;
    gap: 5px;
    align-items: center;
  }
  
.select-dropdown {
    background-color: #E0DEDE; /* Change the background color as needed */
    color: black; /* Change the text color as needed */
    border: none; /* Remove the border */
    padding: 5px; /* Add some padding for better appearance */
  }
 
.apply-button {
  /* padding-left: 50px; 
  padding-top: 5px;
  text-align: center;
  */
  border-radius: 6px;
  background-color:white;
  padding: 0.5em 1.5em;
  border: 0;
  color: #33415C;
  transition: 0.25s ease;
  cursor: pointer;
  /*position: absolute;
  top: 70px;
  right: 200px;*/
  &:hover,
  &:focus {
    background-color: #c7c7c7;
  }

}
 
  /* Add the inhaler and checkbox styles as mentioned in the previous response */
.inhaler-label {
  color: black; /* Set text color to black */
  text-align: left; /* Align text to the left */
  display: flex; /* Use flex to align checkboxes and labels */
  align-items: center; /* Vertically center the content */
  justify-content: flex-start; /* Horizontally align content to the left */
  margin-left: 50px; /* Adjust the left margin as needed */
  margin-top: 20px;
}

.inhaler-label input[type="checkbox"] {
  margin: 0; /* Add margin to the right of the checkbox */
  padding: 0;
}
.inhaler-label {
  justify-content: flex-start; /* Ensure that within the .container, .inhaler-label aligns to the left */
}
.centered-content {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center horizontally */
  margin: 0 auto;
}
.checkbox-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
 /* justify-content: flex-start;*/
  margin-left: 40px; /* Adjust the margin as needed */
  margin-top: 40px;
}
.container {
  display: flex;
  flex-direction: column;
 /* align-items: center;*/
 /* justify-content: */ /*flex-start; Keep this line to center the graphs */
  height: 100vh;
  margin-top: 20px;
}

.time-filter {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 40px; /* Adjust the margin to create space between devices and the time filter */
  margin-top: 40px;
}
.container-top {
  display: flex;
  flex-direction: row; /* Change flex-direction to row */
  align-items: flex-start;
  justify-content: flex-start; /* If you want them to start from the left */
  height: 100vh;
  margin-top: 20px;
}

/* Update your CSS as follows */

.graph-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin-top: 20px; /* Add some margin between graphs */
}

.graph-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.graph {
  width: 700px;
  height: 650px;
}

.graph h2 {
  margin: 0; /* Remove margin for the title to position it closer to the graph */
}

.container-opt {
  display: flex;
  justify-content: space-between; /* Align items with space between */
  align-items: center; /* Align items at the start vertically */
}


/* Style the select element itself */
select {
  width: 100%; /* Make the select element fill the available width */
  padding: 10px; /* Add some padding for better visual appearance */
  border: 1px solid #ccc; /* Add a border for a clear visual distinction */
  border-radius: 5px; /* Round the corners of the select element */
  background-color: #fff; /* Set a background color */
  font-size: 16px; 
  
}

.inhaler-label {
  color: black; /* Set text color to black */
  text-align: left; /* Align text to the left */
  display: flex; /* Use flex to align checkboxes and labels */
  align-items: center; /* Vertically center the content */
  justify-content: flex-start; /* Horizontally align content to the left */
  margin-left: 50px; /* Adjust the left margin as needed */
  margin-top: 20px;
}

.select-container h2 {
  color: black; /* Set text color to black */
  margin-right: 20px; /* Adjust the right margin as needed for spacing */
}

.header-select {
  color: black; /* Set text color to black */
  text-align: left; /* Align text to the left */
  display: flex;
  align-items: center; /* Vertically center the content */
  justify-content: flex-start; /* Horizontally align content to the left */
  flex-direction: column; /* Change to column layout */
 /* margin-top: 20px; */
  margin-right: 10px;
}

/* Other styles remain the same */

.select-wrapper {
  display: flex;
  align-items: center; /* Vertically center the content */
  justify-content: flex-start; /* Horizontally align content to the left */
  margin-bottom: 20px; /* Use margin-bottom to create space */
  flex-direction: column;
  margin-right: 10px;
  margin-top: 20px;
  
}


.apply-button-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /*margin-top: 10px;*/ /* Adjust the top margin to create space between inhalers and the button */
  margin-left: 200px; 
}

.button-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 10px; /* Adjust the top margin to create space between inhalers and the button */
  margin-left:70px; 
}

.dates-button-container{
  display: flex;
  flex-direction: column; /* Change back to row to display elements side by side */
  align-items: flex-start;
  margin-top: 10px;

}

.date-filter-container {
  display: flex;
  flex-direction: row; /* Change back to row to display elements side by side */
  align-items: flex-start;
  margin-top: 10px;
  margin-right: 650px;
}


.date-filter{
  color: black; /*pode esborrar lo d'abaix*/
  display: flex;
  flex-direction: column; /* Change back to row to display elements side by side */
  align-items: flex-start;
  width: 200px;
}
.date{
  color: black;
  margin-left: 30px;
}
/*.date-filter label {
  color: black;
}*/

.apply-dates-button {
  margin-left: 80px;
   border-radius: 6px;
   background-color:white;
   padding: 0.5em 1.5em;
   border: 0;
   color: #33415C;
   transition: 0.25s ease;
   cursor: pointer;
   
   &:hover,
   &:focus {
     background-color: #c7c7c7;
   }

 }