.auth-app-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(to right, rgb(23, 36, 62), #32415C, #2a4475);
  color: white;
  padding: 10px 20px; /* Adjust the padding to reduce the header's height */
}

.header-container {
  background: linear-gradient(to right, rgb(23, 36, 62), #32415C, #2a4475);
  text-align: left;
  padding: 10px 0; /* Adjust the padding to match the header's padding */
}

.auth-logo {
  display: flex;
  align-items: center;
  gap: 10px;
}

.auth-logo img {
  width: 45px; /* Adjust the logo width */
  height: auto; /* Let the height adjust automatically to maintain aspect ratio */
}
.teva {
  margin: 0;
}

.container_gesab {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start; /* Align content to the top of the container */
  height: 100%; /* Adjust this value if needed */
  margin-top: 20px; /* Add separation between header and content */
  width: 20%;
}
.centered-content_gesab {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto; /* Horizontally center the content */
}
.date-inputs {
  margin-top: 0px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-bottom: 5px;
  align-items: flex-start;
}

.filter-header {
  color: black;
  padding-left: 40px;
}

.date-input {
  display: flex;
  gap: 5px;
  align-items: center;
}

.select-dropdown {
  background-color: #E0DEDE; /* Change the background color as needed */
  color: black; /* Change the text color as needed */
  border: none; /* Remove the border */
  padding: 5px; /* Add some padding for better appearance */
}

.apply-button_gesab {
/* padding-left: 50px; 
padding-top: 5px;
text-align: center;
*/
border-radius: 6px;
background-color:white;
padding: 0.5em 1.5em;
border: 0;
color: #33415C;
transition: 0.25s ease;
cursor: pointer;
/*position: absolute;
top: 70px;
right: 200px;*/
&:hover,
&:focus {
  background-color: #c7c7c7;
}

}

/* Add the inhaler and checkbox styles as mentioned in the previous response */
.inhaler-label {
color: black; /* Set text color to black */
text-align: left; /* Align text to the left */
display: flex; /* Use flex to align checkboxes and labels */
align-items: center; /* Vertically center the content */
justify-content: flex-start; /* Horizontally align content to the left */
}

.inhaler-label input[type="checkbox"] {
margin: 0; /* Add margin to the right of the checkbox */
padding: 0;
}
.inhaler-label {
justify-content: flex-start; /* Ensure that within the .container, .inhaler-label aligns to the left */
}
.centered-content {
display: flex;
flex-direction: column;
align-items: center; /* Center horizontally */
margin: 0 auto;
}
.checkbox-container_gesab {
display: flex;
flex-direction: row;
align-items: flex-start;
/* justify-content: flex-start;*/
padding-left: 40px; /* Adjust the margin as needed */
padding-top: 40px;
width: 750px;
margin-left: 0px;
margin-right: 20px;
}
.container {
display: flex;
flex-direction: column;
/* align-items: center;*/
/* justify-content: */ /*flex-start; Keep this line to center the graphs */
height: 100%;
margin-top: 20px;
}

.time-filter {
display: flex;
flex-direction: column;
align-items: flex-start;
margin-left: 40px; /* Adjust the margin to create space between devices and the time filter */
margin-top: 40px;
}
.container-top {
display: flex;
flex-direction: row; /* Change flex-direction to row */
align-items: flex-start;
justify-content: flex-start; /* If you want them to start from the left */
height: 100vh;
margin-top: 20px;
}

/* Update your CSS as follows */

.graph-container {
display: flex;
flex-direction: column;
align-items: center;
gap: 20px;
margin-top: 20px; /* Add some margin between graphs */
}

.graph-section_gesab {
display: flex;
flex-direction: column;
align-items: normal;
gap: 10px;
height: 450px;
}

.graph_gesab {
width: 700px;
height: 650px;
}

.graph h2 {
margin: 0; /* Remove margin for the title to position it closer to the graph */
}

.container-opt_gesab {
display: block;
justify-content: space-between; /* Align items with space between */
align-items: center; /* Align items at the start vertically */
}


/* Style the select element itself */
select {
width: 100%; /* Make the select element fill the available width */
padding: 10px; /* Add some padding for better visual appearance */
border: 1px solid #ccc; /* Add a border for a clear visual distinction */
border-radius: 5px; /* Round the corners of the select element */
background-color: #fff; /* Set a background color */
font-size: 16px; 

}

.inhaler-label {
color: black; /* Set text color to black */
text-align: left; /* Align text to the left */
display: flex; /* Use flex to align checkboxes and labels */
align-items: center; /* Vertically center the content */
justify-content: flex-start; /* Horizontally align content to the left */
margin-left: 50px; /* Adjust the left margin as needed */
margin-top: 20px;
}

.select-container_gesab{
width: 200px;
padding-top: 15px;

}

.select-container h2 {
color: black; /* Set text color to black */
margin-right: 20px; /* Adjust the right margin as needed for spacing */
}

.header-select_gesab {
color: white; /* Set text color to black */
text-align: left; /* Align text to the left */
display: flex;
align-items: center; /* Vertically center the content */
justify-content: flex-start; /* Horizontally align content to the left */
flex-direction: column; /* Change to column layout */
/* margin-top: 20px; */
margin-right: 10px;
}

/* Other styles remain the same */

.select-wrapper_gesab {
display: flex;
align-items: center; /* Vertically center the content */
justify-content: flex-start; /* Horizontally align content to the left */
margin-bottom: 20px; /* Use margin-bottom to create space */
flex-direction: column;
margin-right: 10px;
margin-top: 20px;

}


.apply-button-wrapper_gesab {
width: 150px;
margin: 0px;
padding-top: 60px;

}

.button-wrapper_gesab {
display: flex;
flex-direction: column;
align-items: flex-start;
margin-top: 25px; /* Adjust the top margin to create space between inhalers and the button */
margin-left:20px; 
}

.dates-button-container_gesab{
display: flex;
flex-direction: column; /* Change back to row to display elements side by side */
align-items: flex-start;
margin-top: 10px;
width: 600px;

}

.date-filter-container_gesab {
display: flex;
flex-direction: row; /* Change back to row to display elements side by side */
align-items: flex-start;
margin-top: 10px;
/* margin-right: 650px; */
}


.date-filter_gesab{
color: black; /*pode esborrar lo d'abaix*/
display: flex;
flex-direction: column; /* Change back to row to display elements side by side */
align-items: flex-start;
width: 200px;
}
.date_gesab{
color: white;
padding-left: 30px;
padding-bottom: 22px;
}
/*.date-filter label {
color: black;
}*/

.apply-dates-button_gesab {
margin-left: 0;
 border-radius: 6px;
 background-color:white;
 padding: 0.5em 1.5em;
 border: 0;
 color: #33415C;
 transition: 0.25s ease;
 cursor: pointer;
 
 &:hover,
 &:focus {
   background-color: #c7c7c7;
 }

}



/* A partir d'aqui coses que poden fallar i que requereixin esborrar i tornar a pujar */

hr {
display: block;
unicode-bidi: isolate;
margin-block-start: 0em;
margin-block-end: 0em;
margin-inline-start: auto;
margin-inline-end: auto;
overflow: hidden;
border-style: inset;
border-width: 1px;
width: 700px;
}

gesab_body{
height: fit-content;
width: fit-content;
}
gesab_section{
margin-top: 20px;
}
gesab_separator{
position:relative;
}
gesab_h2{
font-size: 25px;
}
gesab_warning{
width: 350px;
height: 150px;
background-color: #5BB6DB;
color: white;
border-radius: 2rem;
box-shadow: 1px 3px 1px black;
margin-left: 5%;
font-size: 30px;
text-align: center;
}
p_name{
font-size: 25px;
font-family: 'Roboto';
}
welcome{
display: block;
padding-top: 20px;
font-family: 'Roboto';
color: white;
font-size: 15px;
}
#gesab_main{
display: block;
width: 700px;
height: 350px;
padding: 20px;
background-color: #446076; /* Revisar background */
color: white;
border-radius: 0.5rem;
box-shadow: 1px 3px 1px black;
}
#gesab_sensors{
display: block;
width: 700px;
height: 370px;
padding: 20px;
background-color: #446076; /* Revisar background */
color: white;
font-family: 'Roboto';
border-radius: 0.5rem;
box-shadow: 1px 3px 1px black;
margin-top: 20px;
}

#graphs_gesab{
display: flex;
margin-top: 5%;
}

#llegenda{
display: flex;
font-family: 'Roboto';
}

#llegenda .text1{
background-color: transparent;
position: relative;
margin-left: 40px;
}
#llegenda .text2{
background-color: transparent;
position: relative;
margin-left: 155px;
}
#llegenda .text3{
background-color: transparent;
position: relative;
margin-left: 170px;
}
#llegenda .text4{
background-color: transparent;
position: relative;
margin-left: 55px;
}
#llegenda .text5{
background-color: transparent;
position: relative;
margin-left: 130px;
}
#llegenda .text6{
background-color: transparent;
position: relative;
margin-left: 130px;
margin-bottom: 20%;
}

#gesab_table{
display: block;
width: 700px;
height: 640px;
padding: 20px;
background-color: #446076; /* Revisar background */
color: white;
border-radius: 0.5rem;
box-shadow: 1px 3px 1px black;
margin-top: 20px;
}

#gesab_main .chart{
position: relative;
}

#gesab_main .chart2{
position: relative;
left: 50px;
}

#gesab_main .chart3{
position: relative;
left: 100px;
}

#gesab_sensors .chart{
position: relative;
}

#gesab_sensors .chart2{
position: relative;
left: 50px;
}

#gesab_sensors .chart3{
position: relative;
left: 100px;
}

.gesab_cabecera {
position: relative;
background-color: #145c8b; /* Azul */
color: white; /* Texto blanco */
text-align: left;
line-height: 5%;
margin-bottom: 18px;
width: auto;
height: 40px;
}


/* Barra lateral fixe */

.lateral{
box-sizing: border-box;
width: max(5%, 100px);
height: 100%;
position: fixed;
padding-top: 30px;
background: #3F6278;
}
.principal{
background: linear-gradient(to right, #163954, #559FCD);
padding-left: max(10%, 150px);
height: 100%;
text-align: justify;
color: white;
padding-bottom: 20%;
}

.lateral ul{
list-style:none;
padding-left:0;
margin-top:0;
}
.lateral ul li{
display:block;
position: relative;
padding: 12px 15px;
margin-left:max(1%, 5px);
/* border-bottom:1px solid #060696 */
}
.lateral ul li a{
color: black;
text-decoration:none; 
}

.lateral ul li a:after{
content: ' ';
position:absolute;
width: 100%;
height: 100%;
top:0;
left:0;
}

.lateral ul li:hover, .lateral ul li:hover a{
background: rgb(255, 255, 255);
border-radius: 200px;
}

.console-label_gesab {
font-size: 14px;
color: white;
font-family: 'Roboto';
padding: 0px 10px;
}


@keyframes rellenar{
to{
  stroke-dasharray: var(--porcentaje) 570;
}
}

.porcentajes{
position:relative;
}
.porcentajes span{
position: absolute;
top: 0%;
left: 0%;
bottom: 0%;
right: 0%;
display: flex;
align-items: center;
justify-content: center;
font: 25px/1em 'Roboto';
}

circle{
fill: none;
stroke-width: 20;
transform: rotate(-90deg);
transform-origin: 50%;
stroke-dasharray: 100 0;
stroke: #b3a9a9d3;
}

circle:nth-child(2){
stroke: rgb(88, 148, 216);
stroke-dasharray: 0 570;
animation: rellenar .35s linear forwards;
}

historic_section{
margin-top: 20px;
}

#historic_box{
display: block;
width: 700px;
height: 440px;
padding: 20px;
background-color: #c2d8e9bf; /* Revisar background */
color: white;
border-radius: 0.5rem;
box-shadow: 1px 3px 1px black;
margin-bottom: 20px;
}

.tab {
margin-inline-start: 100px;
}


select{
  width: 60%;
  background-color: #e0dede;
  border: none;
  margin: 0px auto;
  outline: none;
  padding: 10px;
  width: 550px;
  font-size: 20px;
  font-family: 'Roboto';
}

input{
  width: 50%;
  margin: 0px auto;
}
