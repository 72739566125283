* {
    margin: 0;
    padding: 0em;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }
  
  .generalbutton {
    display:inline-block;
    border:0.18em solid #000000;
    padding: 0px 6px 1px 6px;
    margin: 0px 0px 0px 7px;
    line-height: 14pt;
    border-radius:0px;
    box-sizing: border-box;
    text-decoration:none;
    color:#000000;
    text-align:center;
    transition: all 0.1s;
    font-size: 10pt;
    font-weight: 500;
  }
  
  .generalbutton:hover{
    background-color:#d1d1d1;
    cursor: pointer;
  }
  
  .generalbuttonicon {
    display:inline-block;
    border:0.18em solid #394475;
    padding: 0px 6px 1px 6px;
    margin: 0px 0px 0px 7px;
    border-radius:0px;
    box-sizing: border-box;
    text-decoration:none;
    color:#000000;
    text-align:center;
    transition: all 0.1s;
    font-size: 10pt;
    font-weight: 500;
  }
  
  .generalbuttonicon:hover{
    cursor: pointer;
    background-color: #d1d1d1;
  }
  
  .button {
    display: inline-block;
    padding: 15px 25px;
    font-size: 16px;
    cursor: pointer;
    width: 50%;
    text-align: center;
    text-decoration: none;
    outline: none;
    color: #000000;
    background-color: #eeeeee;
    border: 0.18em solid #394475;
    border-radius: 15px;
    box-shadow: 0 9px #999;
    margin: 20px;
  }
  
  .button:hover {background-color: #ffffff}
  
  .button:active {
    background-color: #ffffff;
    box-shadow: 0 5px #666;
    transform: translateY(4px);
  }
  
  .button2 {
    display:inline-block;
    border:0.18em solid #000000;
    padding: 15px 25px;
    margin: 20px 20px;
    cursor: pointer;
    width: 50%;
    text-align: center;
    border-radius:5px;
    text-decoration:none;
    color:#000000;
    background-color: #f0f0f0;
    transition: all 0.1s;
    font-size: 12pt;
    font-weight: 500;
    box-shadow: 0 9px #999;
  }
  
  .button2:hover {background-color: #ffffff}
  
  .button2:active {
    background-color: #ffffff;
    box-shadow: 0 5px #666;
    transform: translateY(4px);
  }