dl {
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 5%;
}

dt {
  grid-column-start: 1;
  text-align: right;
  padding: 20px 5px 0px 5px;
  font-weight: bold;
}

dd {
  grid-column-start: 2;
  text-align: left;
  padding: 5px;
}