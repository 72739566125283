.body-auth{
	width: 320px;
	height: 420px;
	margin: 0;
	padding: 0;
	display: flex;
	justify-content: center;
	font-family: 'Jost', sans-serif;
	background: linear-gradient(to bottom, rgb(23, 36, 62), #32415C, #2a4475);
	position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
	border-radius: 10px;
}
.main{
	width: 320px;
	/*width: 100%;*/
	font-size: 12pt;
	/*height: 500px;*/
	overflow: hidden;
	border-radius: 10px;
	/*box-shadow: 5px 20px 50px #526ea1;*/
}
#chk{
	display: none;
}
.signup{
	position: relative;
	width:100%;
	height: 100%;
}
label{
	color: #fff;
	font-size: 1.5em;
	justify-content: center;
	display: flex;
	margin: 30px;
	font-weight: bold;
	/* cursor: pointer; */
	transition: .5s ease-in-out;
}
input{
	font-size: 8pt;
	width: 60%;
	height: 20px;
	background: #e0dede;
	justify-content: center;
	display: flex;
	margin: 20px auto;
	padding: 10px;
	border: none;
	outline: none;
	border-radius: 5px;
}
.button-auth{
	width: 60%;
	height: 40px;
	margin: 10px auto;
	justify-content: center;
	display: block;
	color: #344057;
	background: #C7C7C7;
	font-size: 1em;
	font-weight: bold;
	margin-top: 20px;
	outline: none;
	border: none;
	border-radius: 5px;
	transition: .2s ease-in;
	cursor: pointer;
}
.button-auth:hover{
	background: #C7C7C7;
}
.login{
	height: 400px;
	background: #eee;
	border-radius: 60% / 10%;
	/* transform: translateY(-120px);
	transition: .8s ease-in-out; */
}
.login label{
	color: #32415C;
	transform: scale(1);
	font-size: 1.5em;
}

/* #chk:checked ~ .login{
	transform: translateY(-400px);
}
#chk:checked ~ .login label{
	transform: scale(1);	
}
#chk:checked ~ .signup label{
	transform: scale(.6);
} */
.auth-app-header {
	display: flex;
    justify-content: flex-start; /* Align to the left */
    align-items: flex-start; /*flex*/
    gap: 4rem;
    max-width: 100%; /* Allow the header to span the entire width */
    margin: 0 auto; /* Center the entire header on the page */
    padding: 0 20px;  /*Add horizontal padding for spacing */
}
.espot {
    margin: 0;
}
  .auth-logo-icon-es {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 45px; /* Adjust the logo width */
		height: auto; /* Let the height adjust automatically to maintain aspect ratio */
		margin-right: 30px;
	}
  .auth-logo-es {
	  display: flex;
	  /*align-items: center;
	  padding-bottom: 1rem;
	  padding-top: 1rem;*/
	  width: 45px; /* Adjust the logo width */
	  height: auto; 
	  align-items: center;
	  gap: 10px;
	}
  .auth-app-header-navigation {
	  @media (max-width: 1200px) {
		  display: none;
	  }
  }
  
  .auth-app-header-actions {
	  display: flex;
	  align-items: center;
	  @media (max-width: 1200px) {
		  display: none;
	  }
  }
  
  .auth-app-header-logo {
	  display: flex;
	  justify-content: center;
  }
  .header-container {
    background: linear-gradient(to right, rgb(23, 36, 62), #32415C, #2a4475); /* Background gradient spanning side to side */
    text-align: left; /* Align content to the left */
    padding: 10px 0; /* Add padding for spacing */
}
.auth-logo h1 {
    margin: 0;
    font-size: 36px;
}

h1 {
    font-size: 36px;
    margin: 0;
    padding-top: 0px;
}