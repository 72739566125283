dlc {
  margin: 0 auto;
  display: grid;
  grid-template-columns: 2fr 0fr;
  grid-column-gap: 0;
}

dtc {
  grid-column-start: 1;
  text-align: left;
  padding: 20px 0px 10px 10px;
  font-weight: bold;
  border-bottom: dashed;
  border-width: thin;
}

ddc {
  grid-column-start: 2;
  text-align: right;
  padding: 20px 10px 10px 0px;
  display: flex;
  vertical-align: bottom;
  align-content: flex-end;
  align-items: flex-end;
  flex-wrap: wrap;
  justify-content: flex-end;
  flex-direction: column;
  border-bottom: dashed;
  border-width: thin;
}