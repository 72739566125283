@import url("https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

:root {
	--c-gray-900: #000000;
	--c-gray-800: #1f1f1f;
	--c-gray-700: #2e2e2e;
	--c-gray-600: #313131;
	--c-gray-500: #969593;
	--c-gray-400: #c7c7c7;
	--c-gray-300: #aaadb6;
	--c-gray-200: #f1f1f1;
	--c-gray-100: #ffffff;

	--c-green-500: #45ffbc;
	--c-olive-500: #e3ffa8;

	--c-white: var(--c-gray-100);

	--c-text-primary: var(--c-gray-100);
	--c-text-secondary: var(--c-gray-200);
	--c-text-tertiary: var(--c-gray-400);
}

.body-nav {
	line-height: 1.5;
	min-height: 100vh;
	font-family: "Be Vietnam Pro", sans-serif;
	background-color: var(--c-gray-100);
	color: var(--c-text-primary);
	display: flex;
	padding-top: 3vw;
	padding-bottom: 3vw;
	justify-content: center;
}


.body-nav *,
.body-nav *:before,
.body-nav *:after {
	box-sizing: border-box;
}

img {
	display: block;
	max-width: 100%;
}

button,
select,
input,
textarea {
	font: inherit;
}

a {
	color: inherit;
}

.responsive-wrapper {
	width: 90%;
	max-width: 1600px;
	margin-left: auto;
	margin-right: auto;
}



/*.app-header {
	display: grid;
	grid-template-columns: minmax(min-content, 175px) minmax(max-content, 1fr) minmax(
			max-content,
			400px
		);
	column-gap: 4rem;
	align-items: flex-end;
	justify-content: center;
	@media (max-width: 1200px) {
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-bottom: 1px solid var(--c-gray-600);
	}
}
*/
.app-header {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  gap: 4rem;
  max-width: 700px;
  margin: 40px auto 20px; /* Center the entire .app-header container on the page */
  margin-left: 500px;
  @media (max-width: 1200px) {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid var(--c-gray-600);
  }
}
.logo-icon {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 250px;
	height: 80px;
	margin-right: 0.5rem; /* Add a left margin value here */
  }
.logo {
	display: flex;
	align-items: center;
	padding-bottom: 1rem;
	padding-top: 1rem;
	width: 400px;
	border-bottom: 1px solid var(--c-gray-600);
  
	@media (max-width: 1200px) {
	  border-bottom: 0;
	}
  }
.app-header-navigation {
	@media (max-width: 1200px) {
		display: none;
	}
}

.app-header-actions {
	display: flex;
	align-items: center;
	@media (max-width: 1200px) {
		display: none;
	}
}

.app-header-logo {
	display: flex;
	justify-content: center;
}

.app-header-actions-buttons {
	display: flex;
	border-left: 1px solid var(--c-gray-600);
	margin-left: 2rem;
	padding-left: 2rem;

	& > * + * {
		margin-left: 1rem;
	}
}

.app-header-mobile {
	display: none;
	@media (max-width: 1200px) {
		display: flex;
	}
}


/*.logo {
	display: flex;
	align-items: center;
	padding-bottom: 1rem;
	padding-top: 1rem;
	width: 400px;
	border-bottom: 1px solid var(--c-gray-600);
	@media (max-width: 1200px) {
		border-bottom: 0;
	}
}*/

/*.logo-icon {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 250px;
	height: 80px;
	margin-right: 0.5rem; 
}
*/
.logo-title {
	display: flex;
	flex-direction: column;
	line-height: 1.25;
	margin-left: 2rem;
	span:first-child {
		color: var(--c-text-primary);
		font-size: 1.5em; /* Increase the font size as needed */
	}
	span:last-child {
		color: var(--c-text-tertiary);
		font-weight: 200;
	}
}

.navigation {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	color: var(--c-text-tertiary);
	a {
		display: flex;
		align-items: center;
		text-decoration: none;
		transition: 0.25s ease;

		* {
			transition: 0.25s ease;
		}

		i {
			margin-right: 0.75rem;
			font-size: 1.25em;
			flex-shrink: 0;
		}

		& + a {
			margin-top: 1.25rem;
		}

		&:hover,
		&:focus {
			transform: translateX(4px);
			color: var(--c-text-primary);
		}
	}
}

.tabs {
	display: flex;
	justify-content: space-between;
	color: var(--c-text-tertiary);
	border-bottom: 1px solid var(--c-gray-600);

	a {
		padding-top: 1rem;
		padding-bottom: 1rem;
		text-decoration: none;
		border-top: 2px solid transparent;
		display: inline-flex;
		transition: 0.25s ease;
		&.active,
		&:hover,
		&:focus {
			color: var(--c-text-primary);
			border-color: var(--c-text-primary);
		}
	}
}

.icon-button {
	width: 32px;
	height: 32px;
	border-radius: 50%;
	border: 0;
	background-color: transparent;
	border: 1px solid var(--c-gray-400);
	color: var(--c-text-primary);
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	transition: 0.25s ease;
	flex-shrink: 0;
	margin-left: 3px;
	margin-right: 1rem;
	&.large {
		width: 42px;
		height: 42px;
		font-size: 1.25em;
	}

	i {
		transition: 0.25s ease;
	}

	&:hover,
	&:focus {
		background-color: var(--c-gray-600);
		box-shadow: 0 0 0 4px var(--c-gray-800), 0 0 0 5px var(--c-text-tertiary);
	}
}

.tiles {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	column-gap: 1rem;
	row-gap: 0.5rem;
	margin-top: 60px;
	max-width: 700px;
	margin: 0 auto;
	@media (max-width: 400px) {
		grid-template-columns: repeat(1, 1fr);
	}
}

.tile {
	padding: 0.6rem;
	border-radius: 8px;
    background: linear-gradient(to bottom, #aaadb6, #c7c7c7);
	color: #33415C;
	min-height: 10px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	position: relative;
	transition: 0.25s ease;
	width: 200px;
	height: 120px;
	&:hover {
		transform: translateY(-5px);
	}

	&:focus-within {
		box-shadow: 0 0 0 2px var(--c-gray-800), 0 0 0 4px var(--c-gray-400);
	}

	a {
		text-decoration: none;
		display: flex;
		align-items: center;
		justify-content: space-between;
		font-weight: 600;

		.icon-button {
			color: inherit;
			border-color: inherit;
			&:hover,
			&:focus {
				background-color: transparent;
				i {
					transform: none;
				}
			}
		}

		&:focus {
			box-shadow: none;
		}

		&:after {
			content: "";
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
		}
	}
}

.tile-header {
	display: flex;
	/*background-color:var(--c-gray-400);*/
	align-items: center;
	margin-top: 4px;
	margin-left: 5px;
	margin-bottom: 4px;
	i {
		font-size: 2.5em;
	}

	h3 {
		display: flex;
		flex-direction: column;
		line-height: 1.375;
		margin-left: 0.5rem;
		span:first-child {
			font-weight: 600;
		}

		span:last-child {
			font-size: 0.825em;
			font-weight: 200;
		}
	}
}

.service-section {
	& > h2 {
		font-size: 1.5rem;
		margin-bottom: 1.25rem;
	}
}

.service-section-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	& > * + * {
		margin-left: 1.25rem;
	}

	@media (max-width: 1000px) {
		display: none;
	}
}

.service-section-footer {
	color: var(--c-text-tertiary);
	margin-top: 1rem;
}


.flat-button {
	border-radius: 6px;
	background-color:white;
	padding: 0.5em 1.5em;
	border: 0;
	color: #33415C;
	transition: 0.25s ease;
	cursor: pointer;
	position: absolute;
	top: 70px;
	right: 400px;
	&:hover,
	&:focus {
		background-color: var(--c-gray-200);
	}
}

.mobile-only {
	display: none;
	@media (max-width: 1000px) {
		display: inline-flex;
	}
}

.transfer-section {
	margin-top: 2.5rem;
}

.transfer-section-header {
	display: flex;
	align-items: center;
	width: 100%;
	padding-bottom: 0.75rem;
	border-bottom: 1px solid var(--c-gray-600);
	h2 {
		font-size: 1.5rem;
	}
}



.filter-options {
	margin-left: 1.25rem;
	padding-left: 1.25rem;
	border-left: 1px solid var(--c-gray-600);
	display: flex;
	align-items: center;
	flex: 1 1 auto;

	p {
		& + * {
			margin-left: auto;
			margin-right: 0.75rem;
		}
		color: var(--c-text-tertiary);
		font-size: 0.875rem;
		@media (max-width: 1000px) {
			display: none;
		}
	}
}



.faq {
	margin-top: 1.5rem;
	display: flex;
	flex-direction: column;
	p {
		color: var(--c-text-tertiary);
		font-size: 0.875rem;
	}

	div {
		margin-top: 0.75rem;
		padding-top: 0.75rem;
		padding-bottom: 0.75rem;
		border-top: 1px solid var(--c-gray-600);
		border-bottom: 1px solid var(--c-gray-600);
		font-size: 0.875rem;
		display: flex;
		align-items: center;

		label {
			padding-right: 1rem;
			margin-right: 1rem;
			border-right: 1px solid var(--c-gray-600);
		}

		input {
			border: 0;
			background-color: transparent;
			padding: 0.25em 0;
			width: 100%;
		}
	}
}

.payment-section-footer {
	display: flex;
	align-items: center;
	margin-top: 1.5rem;
}

.save-button {
	border: 1px solid currentColor;
	color: var(--c-text-tertiary);
	border-radius: 6px;
	padding: 0.75em 2.5em;
	background-color: transparent;
	transition: 0.25s ease;
	cursor: pointer;
	&:focus,
	&:hover {
		color: var(--c-white);
	}
}

.settings-button {
	display: flex;
	align-items: center;
	color: var(--c-text-tertiary);
	background-color: transparent;
	border: 0;
	padding: 0;
	margin-left: 1.5rem;
	cursor: pointer;
	transition: 0.25s ease;
	i {
		margin-right: 0.5rem;
	}
	&:focus,
	&:hover {
		color: var(--c-white);
	}
}

input,
select,
a,
textarea,
button {
	&:focus {
		outline: 0;
		box-shadow: 0 0 0 2px var(--c-gray-800), 0 0 0 4px var(--c-gray-400);
	}
}

.dashboard-link {
	color: var(--c-gray-100); 
  }
